import { deleteRequest, getRequest, postRequest, putRequest } from '..';

export function add(firstName, insertion, lastName, emailAddress) {
  return postRequest(
    '/dashboard/users',
    {
      firstName,
      insertion,
      lastName,
      emailAddress,
    },
  );
}

export function edit(id, firstName, insertion, lastName, emailAddress) {
  return putRequest(
    '/dashboard/users',
    {
      id,
      firstName,
      insertion,
      lastName,
      emailAddress,
    },
  );
}

export function getAll() {
  return getRequest('/dashboard/users');
}

export function remove(id) {
  return deleteRequest(`/dashboard/users/${id}`);
}
